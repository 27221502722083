import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

export default function (el, props = {}) {
  // We remove the child elements here so we have them in memory for later use
  const content = [].slice.call(el.children).map(child => el.removeChild(child));

  new Vue({
    ...App,
    propsData: {
      ...props,
      content,
    },
  }).$mount(el);
}
