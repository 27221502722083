export default {
  render(h) {
    return h('div', null, null);
    // const cloneNode = (vnode) => {
    //   if (vnode instanceof Text) {
    //     return vnode.wholeText;
    //   }
    //   console.log(vnode.tagName, vnode.childNodes.length);
    //   const children = vnode.childNodes.length
    //     ? [].slice.call(vnode.childNodes).map(x => cloneNode(x))
    //     : [];
    //   console.log(vnode.attributes);
    //   const attrs = {
    //     staticClass: vnode.className === '' ? null : vnode.className,
    //   };
    //
    //   return h(vnode.tagName, attrs, children);
    // };
    // return cloneNode(this.node);
  },
  mounted() {
    this.$el.appendChild(this.node);
  },
  props: ['node'],
};
