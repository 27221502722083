<template>
  <transition name="fade-left" mode="out-in">
    <div class="popover" :class="{ 'popover--visible': visible }" @click.stop>
      <div class="popover__nav">
        <div @click="toggle(key)"
             class="popover__nav__item"
             :class="{'popover__nav__item--active': key === contentId}"
             v-for="({ title, icon }, key) in navItems" :key="key">
          <div class="popover__nav__item__wrapper">
            <img :src="icon">
            <span>{{title}}</span>
          </div>
        </div>
      </div>
      <div class="popover__content">
        <div class="popover__content__wrapper">
          <transition name="fade-left">
            <DomNode v-if="contentId !== null" :node="visibleContent" :key="contentId"></DomNode>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DomNode from './components/DomNode';

export default {
  name: 'Slideout Contact',
  components: {
    DomNode,
  },
  data() {
    return {
      visible: false,
      contentId: null,
    };
  },
  props: ['content'],
  computed: {
    navItems() {
      return this.content.map(el => ({
        title: el.dataset.title,
        icon: el.dataset.icon,
      }));
    },
    visibleContent() {
      return this.content[this.contentId];
    },
  },
  mounted() {
    document.addEventListener('click', this.hide);
  },
  methods: {
    toggle(contentId) {
      if (this.contentId === contentId) this.hide();
      else this.show(contentId);
    },
    show(contentId) {
      this.visible = true;
      this.contentId = contentId;
    },
    hide() {
      this.visible = false;
      this.contentId = null;
    },
  },
};
</script>

<style lang="scss">
.popover {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: fixed;
  top: 40%;
  right: 0;
  display: flex;
  transform: translateX(100%);
  transition: transform .333s ease;
  &--visible {
    transform: none;
  }

  &__content {
    background: #fff;
    box-shadow: 4px 4px 8px #0002, 2px 2px 4px #0002, 4px 4px 20px #0001;
    z-index: 5;
    padding: 2rem;
    min-height: 350px;
    min-width: 300px;
    box-sizing: border-box;
    &__wrapper {
      position: relative;
    }
  }

  &__nav {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    &__item {
      background: #b3b3b3;
      background: var(--inactive-color, #b3b3b3);
      transform: skewX(calc(var(--skew-angle, 10deg) * -1));
      padding: 1rem 3rem 1rem 1.5rem;
      border-radius: 0.5rem 0 0 0.5rem;
      color: #fff;
      margin-right: -1rem;
      margin-bottom: 0.25rem;
      transition: background .333s ease;
      &__wrapper {
        transform: skewX(var(--skew-angle, 10deg));
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &--active {
        background: #009ee2;
        background: var(--active-color, #009ee2);
      }
      img {
        width: 1.5rem;
        height: 1.5em;
        margin-bottom: .5rem;
      }
    }
  }

  .fade-left {
    &-enter, &-leave-to {
      opacity: 0;
      transform: translateX(100%);
      position: absolute;
      width: 100%;
      top: 0;
    }
    &-enter-active, &-leave-active {
      transition: all .333s ease;
    }
  }
}
</style>
